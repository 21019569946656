@use "../../styling/layout/vars" as *;


.Profile {
  padding-bottom: 50px;
  .ProfileData {
    h3 {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      /* identical to box height */

      text-transform: capitalize;

      /* primary font */

      color: $primaryF;
    }

    h4 {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height */

      text-transform: capitalize;

      /* dark gray */

      color: $gray42;

      span {
        color: $icons;
      }
    }
  }
  .ProfilePI {

    h2 {
      padding-bottom: 8px;
      border-bottom: 1px solid #CBCFD2;
    }
    
    .keys {
      li {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height */

        text-transform: capitalize;

        /* dark gray */

        color:$gray5f;
      }
    }
    .values {
      li {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height */

        text-transform: capitalize;

        /* dark gray */

        color:$primaryF;
      }
    }
  }
  h4.lang {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-decoration: underline;
    margin-bottom: 12px;
  }
}