@use "../../styling/layout/vars" as *;

.Sidebar {
  background-color: white;
  width: 300px;
  z-index: 4;
  height: calc(100vh - 101px);
  @include tra(.5);
  position: absolute; 
  left: -300px;
  top: 101px;

  &.active {
    position: sticky;
    top: 101px;
    left: 0;
  }

  .items {
    a {
      color: $gray5f;
      fill: $gray5f;
      @include tra(.5s);
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        @include center((0 , -50%));
        height: 0;
        width: 2px;
        background-color: $primary;
        @include tra(.5s);
      }

      &:hover::before , &.active::before {
        height: 100%;
      }
      
      &:hover , &.active {
        color: $primary;
        background: #d2000013;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      
      h5 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

@media (max-width: 900px) {
  .Sidebar {
    height: calc(100vh - 76.7px);
    top: 76.7px;
  
    &.active {
      position: fixed;
      top: 76.7px;
      left: 0;
    }
  }
}