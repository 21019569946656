@use "../../styling/layout/vars" as *;


.Profile {
  padding-bottom: 50px;
  .ProfileData {
    img {
      max-width: 100px;
      max-height: 100px;
      @include rad(50%);
      object-fit: fill;
    }
    h5 {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $primaryF;
    }
    h6 {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-transform: capitalize;
      color: $gray5f;
    }
  }
  .ProfilePI {

    h2 {
      padding-bottom: 8px;
      border-bottom: 1px solid #CBCFD2;
    }
    
    .keys {
      li {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height */

        text-transform: capitalize;

        /* dark gray */

        color:$gray5f;
      }
    }
    .values {
      li {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height */

        text-transform: capitalize;

        /* dark gray */

        color:$primaryF;
      }
    }
  }
}