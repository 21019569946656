@use "../../styling/layout/vars" as *;

.AddPlan {
  form {
    h3 {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      /* identical to box height */

      text-decoration-line: underline;
      /* secondery */

      color: $secondry;
    }
    .hidden {
      display: none;
    }
    .items {
      display: flex;
      gap: 10px;

      .item {
        @include rad(4px);
        background-color: $secondry;
        padding: 5px 10px;
        color: white;
        font-size: 12px;
      }
    }
  }
}