@use "../../styling/layout/vars" as *;


.Plan {
  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;  
    color: $secondry;
  }
  p.plan-header {
    font-size: 14px;
    line-height: 21px;

    /* dark gray */

    color: $gray42;
    max-width: 720px;
    margin-bottom: 40px;
  }
  .Tabs {
    .css-61ibgw-MuiButtonBase-root-MuiTab-root {
      font-size: 18px;
      font-weight: 600;
      &[aria-selected="false"] {
        color: #909090;
      } 
    }
    .css-1xtgg15-MuiTabs-indicator {
      background-color: $primary;
      height: 3px;
    }
    .GeneralInfo {
      padding-top: 24px;
      h3 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #070707;
        margin-bottom: 8px;
      }
      p , li {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $gray5f;
        .dark {color: $gray42;}
      }
      ul > li {
        list-style:inside;
      }
      .mb24 {
        margin-bottom: 24px;
      }
    }
    .Pricing {
      padding-top: 24px;

      .price {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: $secondry;
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: $icons;
          margin-left: 4px;
          &:first-of-type {
            font-size: 16px;
            line-height: 24px;
            margin-right: 8px;
            margin-left: 0;
          }
        }
      }
      ul {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $gray42;
        li {list-style: inside;}
      }
    }
    .HowItWorks {
      padding-top: 24px;

      .css-9tmuzo-MuiStepper-root {position: relative;}
      
      .css-9tmuzo-MuiStepper-root::before {
        content: "";
        position: absolute;
        left: 24px;
        z-index: 1;
        top: 16px;
        height: 70%;
        width: 2px;
        background-color: red;
      }

      .css-1pe7n21-MuiStepConnector-root {
        display: none;
      }
      
      .css-vnkopk-MuiStepLabel-iconContainer {
        svg {
          color: $primary;
          width: 50px;
          height: 50px;
    
          .css-s6ov21-MuiStepIcon-text {fill: white;}
        }
    
      }
    
      .css-1wezgmx {
        color: $primary;
        width: 50px;
        height: 50px;
    
        .css-iwkauy , .MuiStepIcon-text {fill: white;}
      }

      .css-14sza3e-MuiStepLabel-root {
        position: relative;
        gap: 16px;
        align-items: flex-start;
        z-index: 2;
      }

    }
    .SuccessRate {
      padding-top: 56px;

      h3 {
        font-weight: 500;
        font-size: 32px;
        line-height: 48px;
        color: $secondry;
        text-align: center;
        margin-bottom: 16px;
      }

      h4 {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: $gray5f;
        margin-bottom: 40px;
        text-align: center;
      }

      .boxes {
        @include grid(calc((100% - 32px) / 3));
        gap: 16px;

        .box {
          text-align: center;
          background: rgba(210, 0, 0, 0.001);
          border: 1px solid #cbcfd260;
          @include rad(8px);
          padding: 24px 12px 16px;
          .icon {
            margin: auto;
            @include rad(8px);
            color: $primary;
            margin-bottom: 18px;
            position: relative;
            z-index: 1;
            background-color: #cbcfd228;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          h5 {
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 8px;
          }
          p {
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            color: $gray42;
          }
        }
        
      }
      
    }
    .RequiredDocs {
      padding-top: 24px;
      h3 {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 8px;
      }
      h4 {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: $gray42;
        margin-bottom: 16px;
      }
      ul {
        padding-left: 10px;
        li {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: $gray42;
          list-style: inside;
        }
      }
    }
  }
  .rect {
    padding: 4px 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    background-color: rgba(203, 207, 210, 0.1);
    @include rad(6px);
    color: $gray5f;

    &:hover {
      background-color: rgba(203, 207, 210, 0.303);
    }

    &:first-of-type {
      background-color: rgba(210, 0, 0, 0.1);
      color: $primary;
      &:hover {
        background-color: rgba(210, 0, 0, 0.26);
      }
    }
  }
}