@use "../../styling/layout/vars" as *;

.CopyWriting {
    position: fixed;
    width: 100%;
    bottom: 0px;
    padding-bottom: 8px;
    left: 50%;
    @include center((-50%,0));
    color: $gray5f;
    text-align: center;
    span {font-weight: 500;}
    background-color: #f2f6f8;
    display: none;
}

@media (max-width:600px) {
    .CopyWriting {
        font-size: 14px;
    }
}