@use "../../styling/layout/vars" as *;

.Login {

  display: flex;
  
  > div {
    flex: 1;
  }
  .image {

    background: radial-gradient(129.3% 133.1% at 61.72% -11.26%, #D0070E 0%, #4A0407 100%);
    box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.2);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    
    img {
      max-width: 100%;
    }
  }

  .content {
    padding-top: 80px;
    text-align: center;
    position: relative;
    background-color: white;

    img {margin: 0 auto 24px; }

    h5 , h6 {
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: $primaryF;
    }

    h5 {margin-bottom: 8px;}
    h6 {margin-bottom: 40px;}

    form {
      display: flex;
      flex-direction: column;
      padding-left: 100px;
      padding-right: 100px;
    }
  }
}

.input {
  position: relative;

  p.error {
    position: absolute;
    color: red;
    top:58px;
    font-size: 12px;
    font-weight: 500;
  }
}



@media (max-width: 900px) {
  .Login {
  
    .image {
      display: none;
    }
  
    .content {
      
      height: 100vh;
      
      form {
        padding-left: 50px;
        padding-right: 50px;
      }
      p {
        font-size: 12px;
      }
    }
  }
}