@use "../../styling/layout/vars" as *;

.CheckE {
  h3 {
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
    /* identical to box height */

    text-transform: capitalize;

    /* secondery */

    color: $secondry;
    margin-bottom: 24px;
  }
  h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    text-transform: capitalize;

    /* FONTS SEC */

    color: #909090;
    margin-bottom: 40px;
  }
  .Question {
    padding: 40px 60px 32px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    margin-bottom: 64px;
    
    p {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      margin-bottom: 40px;
    }
  }
}