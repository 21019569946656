@use "../../styling/layout/vars" as *;


.Navbar {

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 5;
  overflow: hidden;
  
  .profile-img img {
    width: 60px;
    height: 60px;
    @include rad(50%);
  }
  h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $primaryF;
  }
  h6 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-transform: capitalize;
    color: $gray5f;
  }
}

@media (max-width : 900px) {
  .Navbar {
    .logo img {
      max-width: 70%;
    }
    .profile-img img {
      width: 40px;
      height: 40px;
    }
    h5 {
      font-size: 14px;
      line-height: 20px;
    }
    h6 {
      font-size: 12px;
      line-height: 15px;
    }
  }
}