@use "../../styling/layout/vars" as *;

.AddClient {

  .css-zpcwqm-MuiStepConnector-root {
    top: 25px;
    left: calc(-50% + 30px);
    right: calc(50% + 30px);
  }

  .css-15oeqyl {
    top: 25px;
    left: calc(-50% + 30px);
    right: calc(50% + 30px);
  }
  
  .css-vnkopk-MuiStepLabel-iconContainer {
    svg {
      color: white;
      width: 50px;
      height: 50px;

      .css-s6ov21-MuiStepIcon-text {fill: #CBCFD2;}
    }

    .css-1t6n203-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
      color: $primary;
      border: 5px solid rgba(210, 0, 0, 0.5);
      @include rad(50%);
    }
  }

  .css-1wezgmx {
    color: white;
    width: 50px;
    height: 50px;

    .css-iwkauy , .MuiStepIcon-text {fill: #CBCFD2;}
  }

  .MuiStepLabel-labelContainer span {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    text-align: center;
    text-transform: capitalize;

    /* icons color */

    color: $icons;
  }

  .Mui-completed {
    .MuiStepLabel-labelContainer span {
      color: $primary;
    }
  }

  .css-1wezgmx.Mui-completed , .css-1wezgmx.Mui-active {
    color: $primary;
    border: 5px solid rgba(210, 0, 0, 0.5);
    @include rad(50%);

    .MuiStepIcon-text , .css-iwkauy {
      fill: white;
    }
  }

  .box {
    h2 {
      margin-bottom: 12px;
    }
    hr {
      border-color: rgba(214, 214, 214, 0.5);
      margin-bottom: 24px;
    }
    .checks {
      @include grid(250px);
      gap: 30px;
      
      input {
        width: 18px;
        height: 18px;
        accent-color: $secondry;
      }
      
      h5 {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height */

        text-transform: capitalize;

        /* dark gray */

        color: $gray42;
      }
    }
    .inputs {

      @include grid(400px);
      gap: 34px 24px;

      &.quarters {
        @include grid(200px);
        gap: 24px;
      }
      
      &.double {
        @include grid(500px);
        gap: 24px;
      }
      
    }

    &.last {
      hr {
        margin-bottom: 12px;
      }
      h2:not(:first-of-type) {
        margin-bottom: 24px;
        text-decoration: underline;
      }
    }
  }

  .lang-head{
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */

    text-decoration: underline;
    margin-bottom: 24px;
  }

  .lang {
    @include grid(250px);
    gap: 24px;
    margin-bottom: 32px;
  }
}

@media (max-width: 600px) {
  .AddClient {

    .css-zpcwqm-MuiStepConnector-root {
      top: 20px;
      left: calc(-50% + 25px);
      right: calc(50% + 25px);
    }
    
    .css-vnkopk-MuiStepLabel-iconContainer {
      svg {
        width: 40px;
        height: 40px;
  
        .css-s6ov21-MuiStepIcon-text {fill: #CBCFD2;}
      }
    }
  
    .MuiStepLabel-labelContainer span {
      font-size: 12px;
      line-height: 16px;
    }
    
    .box .inputs {
      @include grid(300px);
      gap: 25px 16px;
    }
  }
}