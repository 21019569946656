@use "../../styling/layout/vars" as *;

.Bread {
  li {
    color: $icons;
    font-weight: 500;
    font-size: 12px;

    a:hover {
      color: $primary;
    }

    &:last-of-type {
      color: $primary;
    }
  }
}