@use "../../styling/layout/vars" as *;


.PopUp {
  position: fixed;
  z-index: 1000000000;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background: radial-gradient(129.3% 133.1% at 61.72% -11.26%, #d0070e0f 0%, #4a040711 100%);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: calc( (100vh - 101px - 339px) / 2 );

  .box-popup {
    position: sticky;
    bottom: 0;
    overflow: hidden;
  }
  
  .icon {
    margin-bottom: 34px;
  }

  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;    
    color: $primaryF;
    margin-bottom: 16px;
  }

  h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    text-align: center;
    margin-bottom: 16px;

    /* dark gray */

    color: $gray5f;
  }

  .clientID {
    margin-bottom: 40px;
    padding: 8px 32px;
    background-color: #F6F5F8;
    text-align: center;
    cursor: pointer;

    &:hover {
      background-color: #dfdee0;
    }

    h5 {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      /* identical to box height */

      text-transform: capitalize;

      /* icons color */

      color: $icons;
    }

    h6 {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height */

      text-transform: capitalize;

      /* dark gray */

      color: $gray5f;

    }
  }

}

@media (max-width:600px) {
  .PopUp {
    max-width: 100%;
    padding-bottom: calc( (100vh - 101px - 339px) / 2 );
    
    .icon {
      margin-bottom: 18px;
    }
  
    h1 {
      font-size: 18px;
      line-height: 25px;    
      margin-bottom: 8px;
    }
  
    h3 {
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      margin-bottom: 12px;
    }
  
    .clientID {
      margin-bottom: 20px;
  
      h5 {
        font-size: 10px;
      }
  
      h6 {
        font-size: 12px;
      }
    }
  
  }
}