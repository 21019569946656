@use "./fonts";
@use "./vars" as *;

* {
  margin: 0;
  padding: 0;
  font-size: 100%;
  box-sizing: border-box;
  font-size: 100%;
  @include tra(all .2s linear);
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: $secondry;
  }
}

button {
  border: none;
  outline: none;
}

ul {
  list-style: none;
}

li {
  list-style: none;
}

img {
  display: block;
}

html{
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  text-transform: capitalize;
}

/* Chrome, Safari, Edge, Opera hide number arrows*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input, select {
  outline: none;
}

// Placeholder
input::placeholder {
  @include tra(.5s) ;
}

input:focus::placeholder {
  opacity: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Start Scrollbar */

::-webkit-scrollbar{
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track{
  background-color: $secondry;
}
::-webkit-scrollbar-thumb{
  background-color: $primary ;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}


/* End Scrollbar */
