@use "../../styling/layout/vars" as *;

.Calculator {

  hr {
    margin-bottom: 16px;
  }

  .score-container {

    display: flex;
    justify-content: flex-end;

    .score {
      background-color: #d2000017;
      width: fit-content;
      height: fit-content;
      padding: 4px 8px;
      @include rad(4px);
      span {
        color: $secondry;
        font-weight: bold;
      }
    }

  }

  .radio {
    gap: 12px;
  }

  .radio-label {
    color: #424242;
    margin-bottom: 4px;

    &.ul {
      span { 
        margin-bottom: 20px;
        display: inline-block;
      }
      ul {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;

        li {
          list-style: inside;
        } 
      }
    } 
  }

  .lang-head{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $gray42;
    margin-bottom: 16px;
  }

  .lang {
    @include grid(250px);
    gap: 24px;
  }
  
}

.cumulative {
  width: 300px;
  height: calc(100vh - 101px);
  position: sticky;
  top: 101px;
  display: flex;
  gap: 24px;
  justify-content: end;
  flex-direction: column;

  .content {
    
    width: 100%;
    background-color: rgba(210, 0, 0, 0.05);
    padding: 20px 20px 20px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;


    h4 {
      color: #424242;
      font-weight: 500;
      border-bottom: 1px solid $secondry;
      margin-bottom: 20px;
      padding-bottom: 10px;
      text-align: center;
      font-size: 20px;
    }

    h5 {
      text-align: center;
      font-size: 30px;
      font-weight: 500;
      color: $secondry;
    }

    border-top-left-radius: 20px;
    
  }
}

.css-1iorexy-MuiPaper-root-MuiMenu-paper-MuiPopover-paper , .css-8e2dln {
  max-height: 150px !important ;
}