@use "../../styling/layout/vars" as *;


.ClientsList {
  min-height: calc(100vh - 101px);
  padding-bottom: 60px !important;

  .css-1d6wzja-MuiButton-startIcon>*:nth-of-type(1) {
    font-size: 25px;
  }

  h2 {margin-bottom: 0 !important;}

}

.ActionsBar {

  .input-icon {

    position: relative;
    flex: 1;
    
    &.search {
      flex: 3;
    }

    input , select {
      padding: 12px 10px 12px 40px;
      background: #FFFFFF;
      border: 1px solid #EDEFF3;
      @include rad(8px);
      width: 100%;
    } 

    .icon {
      position: absolute;
      left: 12px;
      top: 50%;
      @include center((0 , -50%));
      color: $icons;
      display: flex;
    }

  }

}

.table {

  max-height: calc(100vh - 350px);
  
  .status {
    .MuiSelect-select {
      font-size: 16px;
      text-transform: capitalize;
    }
  }
}


.main-section {
  padding: 16px 16px 0px 40px;
  flex: 1;
  position: relative;

  h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */

    text-transform: capitalize;

    /* primary font */

    color: $primaryF;
    margin-bottom: 16px;
  }
}

@media (max-width:600px) {
  .main-section {
    padding: 16px 8px 0px 8px;
  }
}