@use "../../styling/layout/vars" as *;


.Plans {
  .input-icon {

    position: relative;
    flex: 1;
    
    &.search {
      flex: 3;
      max-width: 690px;
    }

    input {
      padding: 15px 10px 15px 40px;
      background: #FFFFFF;
      border: 1px solid #EDEFF3;
      @include rad(8px);
      width: 100%;
    } 

    .icon {
      position: absolute;
      left: 12px;
      top: 50%;
      @include center((0 , -50%));
      color: $icons;
      display: flex;
    }

  }

  .Filter {
    .css-gzd9i2-MuiFormLabel-root-MuiInputLabel-root {
      font-weight: 500;
    }
    .slider-label {
      text-transform: uppercase;
      text-align: center;
      color: rgba(0, 0, 0, 0.6);
      font-weight: 500;
    }
  }

  .plansContainer {
    @include grid(354px);
    gap: 12px;
    .Plan {
      overflow: hidden;
      h4 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $primaryF;
      }
      h3 {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: $primaryF;
        margin-bottom: 8px;
      }
      p {
        font-size: 14px;
        line-height: 21px;
        color: $gray5f;
        margin-bottom: 32px;
      }
      .rects {
        display: flex;
        gap: 8px;
        padding-bottom: 12px;
        margin-bottom: 8px;
        border-bottom: 1px solid #CBCFD2;

        .rect {
          padding: 4px 8px;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          background-color: rgba(203, 207, 210, 0.1);
          @include rad(6px);
          color: $gray5f;

          &:hover {
            background-color: rgba(203, 207, 210, 0.303);
          }

          &.active {
            background-color: rgba(210, 0, 0, 0.1);
            color: $primary;
            &:hover {
              background-color: rgba(210, 0, 0, 0.26);
            }
          }
        }
      }
      &:hover {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        transform: translatey(-5px);
        -webkit-transform: translatey(-5px);
        -moz-transform: translatey(-5px);
        -ms-transform: translatey(-5px);
        -o-transform: translatey(-5px);
      }
      a {
        text-align: right;
      }
    }
    margin-bottom: 50px;
  }
}