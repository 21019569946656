@use "./layout/fonts";
@use "./layout/global-roles";
@use "./layout/vars" as *;

body {
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
}

.App {
  background-color: #f2f6f8;
}