@use "../../styling/layout/vars" as *;


.Settings {
  hr {
    margin-bottom: 16px;
  }
  .items {
    @include grid(200px);
    gap: 16px;
  }
  min-height: calc(100vh - 101px);
}